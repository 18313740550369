import Updates from 'lib/Updates'
import ExpoConstants from 'configs/ExpoConstants'

import {
  PUBLIC_API_SCHEME,
  PUBLIC_API_DOMAIN,
  PUBLIC_WEB_SCHEME,
  PUBLIC_WEB_DOMAIN,
} from "@env"

// calling the env variables once will load correctly in jest env.
// maybe it's a bug from the @env package
(() => {
  {PUBLIC_API_SCHEME, PUBLIC_API_DOMAIN, PUBLIC_WEB_SCHEME, PUBLIC_WEB_DOMAIN}
})()

const shared = {
  appStoreUrl: 'https://apps.apple.com/jp/app/id1553246735',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=me.casican',
  appDynamicLinkUrl: 'https://casican.page.link/app',
  appDynamicLinkQR: 'https://casican-assets.s3.ap-northeast-1.amazonaws.com/images/dynamic_link_qr.png',
  prices: {
    annual: {
      lite: 380,
      standard: 880,
    },
    monthly: {
      lite: 480,
      standard: 980,
    }
  },
  firebase: { // dev and staging config
    apiKey: "AIzaSyB_ukYYRpR7NXaiiAaZpD2Bv74oVjFTirA",
    authDomain: "akimachi-dev.firebaseapp.com",
    databaseURL: "https://akimachi-dev.firebaseio.com",
    projectId: "akimachi-dev",
    storageBucket: "akimachi-dev.appspot.com",
    messagingSenderId: "61705664879",
    appId: "1:61705664879:web:f956219b893c78587d509a",
    measurementId: "G-4MZ196T9LY"
  },

  supabase: {
    id: 'jsnmxmixdopvswytbduj',
    anonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impzbm14bWl4ZG9wdnN3eXRiZHVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQzNTExMzIsImV4cCI6MTk4OTkyNzEzMn0.omniXmQi5I_b-1oEv4oDTpDuEJg_DkDnE90crjpaHh8',
  },

  tuneup: {
    collectionId: 'cc657d27-5f7e-43c7-bf96-fb36afdcb614',
    apiKey: 'aee600ff7144982111401ec721e706153ee4a4ae54311a7a5510b3fb928dbec5'
  }
}

export const ENV = {
  test: {
    ...shared,
    experienceId: '@foloinfo/casican-test',
    env: 'test',
    webScheme: 'http',
    apiScheme: 'http',
    domain: 'api.test.co',
    webDomain: 'test.co',
    get webBaseUrl(){ return `${this.webScheme}://${this.webDomain}` },
    get webOnlyBaseUrl(){ return `${this.webScheme}://web.${this.webDomain}` },
    get baseUrl(){ return `${this.webScheme}://${this.domain}` },
    get apiBase(){ return `${this.apiScheme}://${this.domain}/api/v1` },
    get graphqlBase(){ return `${this.apiScheme}://${this.domain}/graphql` },

    amplitude: {
      apiKey: 'test_api_key',
    },
    sentry: '',
    auth: {
      google: {
        expoClientId: 'dummy_expoClientId',
        iosClientId: 'dummy_iosClientId',
      },
    },
    stripe: {
      pubKey: 'pk_test_51KOEvTKDjzxthJ26HV11mtm1h5MmhEAVjCTKyxtAAxUJeNtpC0WggFmO41N65JU3Lq9fPbRGa45ZSjZTsNbmTrP900gDrdGsVQ',
    },
  },

  // TODO: allow modification to the configs without changing the committed code.
  // Using ExpoConstants requires restarting the server and annoying so other method is preferred.
  dev: {
    ...shared,
    experienceId: '@foloinfo/akimachi-dev',
    env: 'development',
    webScheme: PUBLIC_WEB_SCHEME,
    apiScheme: PUBLIC_API_SCHEME,
    domain: PUBLIC_API_DOMAIN,
    webDomain: PUBLIC_WEB_DOMAIN,
    get webBaseUrl(){ return `${this.webScheme}://${this.webDomain}` },
    get webOnlyBaseUrl(){ return `${this.webScheme}://web.${this.webDomain}` },
    get baseUrl(){ return `${this.webScheme}://${this.domain}` },
    get apiBase(){ return `${this.apiScheme}://${this.domain}/api/v1` },
    get graphqlBase(){ return `${this.apiScheme}://${this.domain}/graphql` },
    amplitude: {
      apiKey: '',
    },
    sentry: '',
    auth: {
      google: {
        expoClientId: '61705664879-mdl089bpf7an8paetcmgfbfaqmssj25c.apps.googleusercontent.com',
        iosClientId: '61705664879-cm35ujhakjep5qd5knsmr3r6ep1h8ilp.apps.googleusercontent.com',
        androidClientId: '61705664879-cd4p8qfaecol87nq9ui6tl54eulp371l.apps.googleusercontent.com',
        webClientId: '61705664879-a135f243bc06vqrja2d7q2ln6ksvuvmi.apps.googleusercontent.com',
      },
    },
    stripe: {
      pubKey: 'pk_test_51KOEvTKDjzxthJ26HV11mtm1h5MmhEAVjCTKyxtAAxUJeNtpC0WggFmO41N65JU3Lq9fPbRGa45ZSjZTsNbmTrP900gDrdGsVQ',
    },
  },

  staging: {
    ...shared,
    experienceId: '@foloinfo/akimachi-staging',
    env: 'staging',
    webScheme: 'https',
    apiScheme: 'https',
    domain: 'api.casican.cyou',
    webDomain: 'casican.cyou',
    get webBaseUrl(){ return `${this.webScheme}://${this.webDomain}` },
    get webOnlyBaseUrl(){ return `${this.webScheme}://web.${this.webDomain}` },
    get baseUrl(){ return `${this.webScheme}://${this.domain}` },
    get apiBase(){ return `${this.apiScheme}://${this.domain}/api/v1` },
    get graphqlBase(){ return `${this.apiScheme}://${this.domain}/graphql` },

    amplitude: {
      apiKey: '',
    },

    sentry: "https://cd8152f65cf34d45bf17a6c0e74c3e21@o87934.ingest.sentry.io/5412715",

    auth: {
      google: {
        expoClientId: '61705664879-mdl089bpf7an8paetcmgfbfaqmssj25c.apps.googleusercontent.com',
        iosClientId: '61705664879-cm35ujhakjep5qd5knsmr3r6ep1h8ilp.apps.googleusercontent.com',
        androidClientId: '61705664879-cd4p8qfaecol87nq9ui6tl54eulp371l.apps.googleusercontent.com',
        webClientId: '61705664879-a135f243bc06vqrja2d7q2ln6ksvuvmi.apps.googleusercontent.com',
      },
    },

    stripe: {
      pubKey: 'pk_test_51KOEvTKDjzxthJ26HV11mtm1h5MmhEAVjCTKyxtAAxUJeNtpC0WggFmO41N65JU3Lq9fPbRGa45ZSjZTsNbmTrP900gDrdGsVQ',
    },

  },

  production: {
    ...shared,
    experienceId: '@foloinfo/casican',
    env: 'production',
    webScheme: 'https',
    apiScheme: 'https',
    domain: 'api.casican.me',
    webDomain: 'casican.me',
    get webBaseUrl(){ return `${this.webScheme}://${this.webDomain}` },
    get webOnlyBaseUrl(){ return `${this.webScheme}://web.${this.webDomain}` },
    get baseUrl(){ return `${this.webScheme}://${this.domain}` },
    get apiBase(){ return `${this.apiScheme}://${this.domain}/api/v1` },
    get graphqlBase(){ return `${this.apiScheme}://${this.domain}/graphql` },

    amplitude: {
      apiKey: '',
    },

    sentry: 'https://de8f963c2906476283defe35621d6467@o87934.ingest.sentry.io/5420576',

    auth: {
      google: {
        iosClientId: '943889887691-2kkek278pqpvelivnnm46bq2g4jsgv8a.apps.googleusercontent.com',
        androidClientId: '943889887691-s5kfqkqkjl2sit8jj0c4di88mrsf6d57.apps.googleusercontent.com',
        webClientId: '943889887691-u8s7h70ltq5jdjv182u93lefoe737v26.apps.googleusercontent.com',
      },
    },

    stripe: {
      pubKey: 'pk_live_51KOEvTKDjzxthJ26rPFJiV9ZBYHJSNZDUCbqOyNtysYeTaVARI1f8vwlUNBf3FzUkTDcIr5sBukDoOsK0VTwLcGH00vozOR7KE',
    },

    firebase: {
      apiKey: "AIzaSyDZi_kyyC_FXuX1zz8OyYGLT21AFl15Wmk",
      authDomain: "akimachi-d7c79.firebaseapp.com",
      databaseURL: "https://akimachi-d7c79.firebaseio.com",
      projectId: "akimachi-d7c79",
      storageBucket: "akimachi-d7c79.appspot.com",
      messagingSenderId: "630890014182",
      appId: "1:630890014182:web:7de30030d1df71e158e227",
      measurementId: "G-9PZE94NMBQ"
    },

    supabase: {
      id: 'jsnmxmixdopvswytbduj',
      anonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impzbm14bWl4ZG9wdnN3eXRiZHVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQzNTExMzIsImV4cCI6MTk4OTkyNzEzMn0.omniXmQi5I_b-1oEv4oDTpDuEJg_DkDnE90crjpaHh8',
    },

    tuneup: {
      collectionId: 'a0f6bbce-ebc8-4809-bec2-db004fffb091',
      apiKey: '4083df3f17fc897d31160e6adb0250530c2bc6c493358b10a9053b741d8ec4d5'
    },

  }
}

const getEnvVars = () => {
  const channel = Updates.channel
  // web does not have channel
  const env = channel ? channel : ExpoConstants.expoConfig.name
  if (env.indexOf('dev') !== -1) return ENV.dev
  if (env.indexOf('mock') !== -1) return ENV.test
  if (env.indexOf('staging') !== -1) return ENV.staging
  return ENV.production
}

export default getEnvVars()
